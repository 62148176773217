import { Box } from 'components/Box/Box';
import { Icon } from 'components/Icon/Icon';
import { IconName } from 'components/Icon/IconName';
import { TagSimple } from 'components/Tag/TagCombined';
import { Color, colors } from 'theme/theme';

type Props = {
  iconName: IconName;
  text: string;
  badgeColor?: Color;
  textColor?: Color;
};

export function OrgStatBadge({
  iconName,
  text,
  badgeColor = 'lemon',
  textColor = 'mediumContentGrey',
}: Props) {
  return (
    <TagSimple
      $color={colors[textColor]}
      $bg={colors[badgeColor]}
      $borderColor={colors[badgeColor]}
    >
      <Box display="flex">
        <Box pr="6px" display="inline">
          <Icon name={iconName} colorName={textColor} size={12} />
        </Box>
        <span>{text}</span>
      </Box>
    </TagSimple>
  );
}
