import { colors } from 'theme/theme';

export const APPLICATION_STATUSES_ALL = {
  APPLIED:
    /* Status */
    getText('Applied'),
  UNDER_CONSIDERATION:
    /* Status */
    getText('Under Consideration'),
  INTERVIEWING:
    /* Status */
    getText('Interviewing'),
  MADE_OFFER:
    /* Status */
    getText('Made Offer'),
  HIRED:
    /* Status */
    getText('Accepted'),
  DISQUALIFIED:
    /* Status */
    getText('Disqualified'),
} as const satisfies Record<string, string>;

export type PagesToShowByApplicationStatus = Record<
  keyof typeof APPLICATION_STATUSES_ALL,
  number
>;

export const APPLICATION_STATUSES_ALL_KEYS = Object.keys(
  APPLICATION_STATUSES_ALL,
) as Array<keyof typeof APPLICATION_STATUSES_ALL>;

// before that date, referrers were not tracked, so we don't want to show wrong data
export const APPLICATION_REFERRER_SUPPORT_DATE = '2021-04-07T12:00:00.000Z';

const COLOR_MAP = {
  APPLIED: {
    bg: colors.skyBlue,
    color: 'white',
  },
  UNDER_CONSIDERATION: {
    bg: colors.brandBlue,
    color: 'white',
  },
  INTERVIEWING: {
    bg: colors.brightPlum,
    color: 'white',
  },
  MADE_OFFER: {
    bg: colors.brandYellow,
    color: colors.darkContentGrey,
  },
  HIRED: {
    bg: colors.brandGreen,
    color: 'white',
  },
  DISQUALIFIED: {
    bg: colors.elementGrey,
    color: colors.darkContentGrey,
  },
  // RSVP statuses
  GOING: {
    bg: colors.brandGreen,
    color: colors.white,
  },
  INTERESTED: {
    bg: colors.skyBlue,
    color: colors.white,
  },
  NOT_GOING: {
    bg: colors.elementGrey,
    color: colors.darkContentGrey,
  },
};

export const getColor = (key: keyof typeof COLOR_MAP) =>
  COLOR_MAP[key] || COLOR_MAP.APPLIED;

export const RSVP_STATUSES = {
  GOING: getText('Attending'),
  INTERESTED: getText('Interested'),
  NOT_GOING: getText('Not Attending'),
};
