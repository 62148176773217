import { sprintf } from 'sprintf-js';

import { Box } from 'components/Box/Box';
import { Meter } from 'components/Meter/Meter';
import { BodyMicro } from 'components/Text/BodyMicro';

type Props = {
  current: number;
  total: number;
  dollars?: boolean;
  signatures?: boolean;
};

export function FundingMeter({
  current,
  total,
  dollars,
  signatures,
  ...props
}: Props) {
  const roundedTotal = Math.round(total);
  const roundedCurrent = Math.round(current);
  return (
    // TODO: Fix this the next time the file is edited.
    // eslint-disable-next-line react/jsx-props-no-spreading
    <Box {...props}>
      <Box textAlign="right" color="lightContentGrey" mb="4px">
        <BodyMicro>
          {sprintf(
            // TODO: Fix this the next time the file is edited.
            // eslint-disable-next-line no-nested-ternary
            dollars /* TRANSLATORS: remaining amount for volop to be funded */
              ? getText('$%(remaining)s left')
              : signatures
                ? /* TRANSLATORS: remaining signatures for petition */
                  getText('%(remaining)s signatures left')
                : getText('%(remaining)s left'),
            {
              remaining: (roundedTotal - roundedCurrent).toLocaleString(),
            },
          )}
        </BodyMicro>
      </Box>
      <Meter current={roundedCurrent} total={roundedTotal} />
    </Box>
  );
}
