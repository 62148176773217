import invariant from 'invariant';
import styled, { css } from 'styled-components';

import { TagSimple } from 'components/Tag/TagCombined';
import { colors, mediaRetina } from 'theme/theme';
import {
  APPLICATION_STATUSES_ALL,
  RSVP_STATUSES,
  getColor as getStatusColor,
} from 'utils/constants/ats';
import { STATUSES } from 'utils/constants/general/statuses';

export const ALL_STATUSES = {
  ...STATUSES,
  ...APPLICATION_STATUSES_ALL,
  ...RSVP_STATUSES,
  NEW: getText('New Application'),
  INACTIVE: getText('Inactive'),
  ACTIVE: getText('Active'),
  // resume recommender statuses:
  ADDED_NOTE: getText('Added Note'),
  CONTACTED_SUGG_APPL: getText('Contacted'),
  RATING_CHANGED: getText('Ranking Updated'),
  RECOMMENDED: getText('Recommended On'),
};

export type AllStatusType = keyof typeof ALL_STATUSES;

const StyledStatus = styled.div<{
  $color: string;
  $indicatorStyle?: 'outline' | 'solid';
}>`
  position: relative;
  font-size: 14px;
  letter-spacing: 1.8px;
  color: ${colors.lightContentGrey};
  text-transform: uppercase;
  line-height: 1.35;
  padding-left: 21px;

  &::before {
    content: '';
    position: absolute;
    left: 0;
    top: 4px;
    border-radius: 50%;
    ${(props) =>
      props.$indicatorStyle === 'outline'
        ? css`
            width: 8px;
            height: 8px;
            border: solid 1px ${props.$color};
            ${mediaRetina} {
              border-width: 1.5px;
            }
          `
        : css`
            width: 11px;
            height: 11px;
            background-color: ${props.$color};
          `};
  }
`;

// return the first application status
const getApplicationStatus = (status: ReadonlyArray<string> | string) => {
  // TODO: Fix this the next time the file is edited.
  // @ts-expect-error TS(2322): Type 'string | readonly string[]' is not assignabl... Remove this comment to see the full error message
  // eslint-disable-next-line no-param-reassign
  if (!Array.isArray(status)) status = [status];
  // @ts-expect-error TS(2339): Property 'find' does not exist on type 'string | r... Remove this comment to see the full error message
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  return status.find((s: any) => APPLICATION_STATUSES_ALL[s]);
};

type Props = {
  listingStatus: ReadonlyArray<string> | string;
  asTag?: boolean;
  forceSolid?: boolean;
  colorOverride?: string | null;
  textColorOverride?: string | null;
};

export function ListingStatusLabel({
  listingStatus,
  asTag,
  forceSolid,
  colorOverride,
  textColorOverride,
  ...props
}: Props) {
  let status: AllStatusType | undefined;
  let color = colors.alertRed;
  let textColor = 'white';
  const indicatorStyle = forceSolid ? 'outline' : 'solid';

  if (listingStatus.includes('PUBLISHED')) {
    status = 'PUBLISHED';
    color = '#68BD53';
  } else if (listingStatus.includes('REJECTED')) {
    status = 'REJECTED';
    color = colors.brandYellow;
    textColor = colors.darkContentGrey;
  } else if (listingStatus.includes('DRAFT')) {
    status = 'DRAFT';
    color = colors.selectionGrey;
    textColor = colors.darkContentGrey;
  } else if (listingStatus.includes('PENDING')) {
    status = 'PENDING';
    color = '#C6BBD5';
    textColor = colors.darkContentGrey;
  } else if (listingStatus.includes('INVOICE')) {
    status = 'INVOICE';
    color = colors.brandGreen;
  } else if (listingStatus.includes('ARCHIVED')) {
    status = 'ARCHIVED';
    color = '#D0021B';
  } else if (listingStatus.includes('FROZEN')) {
    status = 'FROZEN';
  } else if (listingStatus.includes('HIDDEN')) {
    status = 'HIDDEN';
    color = '#9B489B';
  } else if (listingStatus.includes('PULLED')) {
    status = 'PULLED';
  } else if (listingStatus.includes('DELETED')) {
    status = 'DELETED';
    color = '#B40016';
  } else if (listingStatus.includes('CHECKOUT')) {
    status = 'CHECKOUT';
    color = '#F7C702';
    textColor = colors.darkContentGrey;
  } else if (listingStatus.includes('SPAM')) {
    status = 'SPAM';
  } else if (listingStatus.includes('APPROVED')) {
    status = 'APPROVED';
    color = colors.brandGreen;
  } else if (getApplicationStatus(listingStatus)) {
    const statusColor = getStatusColor(
      getApplicationStatus(listingStatus) || 'APPLIED',
    );
    color = statusColor.bg;
    textColor = statusColor.color;
  } else if (listingStatus === 'ACTIVE') {
    color = colors.brandGreen;
  } else if (listingStatus === 'INACTIVE') {
    color = colors.elementGrey;
    textColor = colors.darkContentGrey;
  } else if (listingStatus === 'NEW') {
    color = colors.lemon;
    textColor = colors.darkContentGrey;
    status = 'NEW';
  } else if (listingStatus === 'ADDED_NOTE') {
    color = colors.brandGreen;
    textColor = colors.white;
    status = 'ADDED_NOTE';
  } else if (listingStatus === 'CONTACTED_SUGG_APPL') {
    color = colors.brandBlue;
    textColor = colors.white;
    status = 'CONTACTED_SUGG_APPL';
  } else if (listingStatus === 'RATING_CHANGED') {
    color = colors.brightPlum;
    textColor = colors.white;
    status = 'RATING_CHANGED';
  } else if (listingStatus === 'RECOMMENDED') {
    color = colors.decorativePlum;
    textColor = colors.white;
    status = 'RECOMMENDED';
  } else if (
    typeof listingStatus === 'string' &&
    Object.keys(RSVP_STATUSES).includes(listingStatus)
  ) {
    const rsvpStatusColors = getStatusColor(
      listingStatus as keyof typeof RSVP_STATUSES,
    );
    color = rsvpStatusColors.bg;
    textColor = rsvpStatusColors.color;
  }

  if (
    typeof listingStatus === 'string' &&
    Object.keys(ALL_STATUSES).includes(listingStatus)
  ) {
    status = listingStatus as AllStatusType;
  }

  if (colorOverride) {
    color = colorOverride;
  }
  if (textColorOverride) {
    textColor = textColorOverride;
  }

  invariant(status, 'Invalid status');

  return asTag ? (
    <TagSimple
      $bg={color}
      $borderColor={color}
      $color={textColor}
      $textTransform="uppercase"
      data-qa-id="listing-status"
    >
      {ALL_STATUSES[status]}
    </TagSimple>
  ) : (
    <StyledStatus
      $color={color}
      $indicatorStyle={indicatorStyle}
      data-qa-id="listing-status"
      // eslint-disable-next-line react/jsx-props-no-spreading
      {...props}
    >
      {ALL_STATUSES[status]}
    </StyledStatus>
  );
}
