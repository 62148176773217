import { sprintf } from 'sprintf-js';

import { ApiAddress } from 'api/types/ApiAddress';
import { ApiRemoteOptionsWithFormData } from 'api/types/ApiRemoteOptions';
import { OrgDashboardApiOrg } from 'modules/orgDashboard/api/types/OrgDashboardApiOrg';
import { getSalaryPeriodHumanName } from 'utils/constants/general/salaryPeriods';

type PaidListingType = 'JOB' | 'INTERNSHIP';

function listingHasUSPrice(
  org: OrgDashboardApiOrg,
  address: ApiAddress,
  remoteOptions: ApiRemoteOptionsWithFormData,
): boolean {
  if (org.address.country === 'US') {
    return true;
  }
  if (remoteOptions.locationType !== null) {
    // is null when address field untouched
    if (remoteOptions.locationType === 'ONSITE') {
      if (address.country === 'US') {
        return true;
      }
    } else if (remoteOptions.remoteZone !== undefined) {
      // REMOTE, HYBRID, with remoteZone defined
      // remoteOptions.useOrgAddress is only used for WORLD, COUNTRY, STATE and is stale otherwise
      const useOrgAddress =
        ['WORLD', 'COUNTRY', 'STATE'].includes(remoteOptions.remoteZone) &&
        remoteOptions.useOrgAddress;
      // address is stale when useOrgAddress is true
      if (!useOrgAddress && address.country === 'US') {
        return true;
      }
      if (remoteOptions.remoteCountry === 'US') {
        return true;
      }
    }
  }
  return false;
}

function singleListingPrice(
  org: OrgDashboardApiOrg,
  listingType: PaidListingType,
  address: ApiAddress,
  remoteOptions: ApiRemoteOptionsWithFormData,
): { dollarPrice: number; creditPrice: number } {
  const usPrice = listingHasUSPrice(org, address, remoteOptions);
  if (usPrice) {
    return {
      dollarPrice: org.prices.usListings[listingType],
      creditPrice:
        org.prices.usListings[listingType] > 0 &&
        (org.promoCredits[listingType] > 0 || org.credits[listingType] > 0)
          ? 1
          : 0,
    };
  }
  return {
    dollarPrice: org.prices.listings[listingType],
    creditPrice:
      org.prices.listings[listingType] > 0 &&
      (org.promoCredits[listingType] > 0 || org.credits[listingType] > 0)
        ? 1
        : 0,
  };
}

export function listingPriceInfo(
  formValues: {
    addresses: ApiAddress[];
    remoteOptions: ApiRemoteOptionsWithFormData[];
  },
  listingType: PaidListingType,
  org: OrgDashboardApiOrg,
): { dollarPrice: number; creditPrice: number }[] {
  return formValues.remoteOptions.map((remoteOptions, index) =>
    singleListingPrice(
      org,
      listingType,
      formValues.addresses[index],
      remoteOptions,
    ),
  );
}

export function listingTotalPriceInfo(
  formValues: {
    addresses: ApiAddress[];
    remoteOptions: ApiRemoteOptionsWithFormData[];
  },
  listingType: PaidListingType,
  org: OrgDashboardApiOrg,
): { dollarPrice: number; creditPrice: number } {
  return listingPriceInfo(formValues, listingType, org).reduce(
    (acc, val) => ({
      dollarPrice: val.dollarPrice + acc.dollarPrice,
      creditPrice: val.creditPrice + acc.creditPrice,
    }),
    { dollarPrice: 0, creditPrice: 0 },
  );
}

// Form helper
export function pricingMessage({
  dollarPrice,
  creditPrice,
}: {
  dollarPrice: number;
  creditPrice: number;
}) {
  return (
    /* TRANSLATORS : in "add to cart" button when org admin creates new listing */
    creditPrice
      ? sprintf(
          getText('$%(dollarPrice)s USD or %(creditPrice)s %(creditLabel)s'),
          {
            dollarPrice,
            creditPrice,
            creditLabel:
              creditPrice > 1 ? getText('credits') : getText('credit'),
          },
        )
      : `$${dollarPrice} USD`
  );
}

// TODO: refactor into generic helper
export function formatSalary(
  salary: number,
  _country: string,
  currency?: string | null,
) {
  const hasDecimal = salary % 1 > 0;

  // round salary unless it's hourly, in which case allow decimals
  const intSalary = hasDecimal ? Number(salary) : Math.round(salary);

  if (currency) {
    if (currency === 'USD') {
      const dollarString = intSalary.toLocaleString(CURRENT_LOCALE, {
        minimumFractionDigits: hasDecimal ? 2 : 0,
      });

      // String.prototype.toLocaleString() uses non-breaking spaces
      // so we're setting it manually here for the sake of consistency
      return `USD\u00A0$${dollarString}`;
    }

    return intSalary.toLocaleString(CURRENT_LOCALE, {
      style: 'currency',
      currency,
      currencyDisplay: 'code',
      minimumFractionDigits: hasDecimal ? 2 : 0,
    });
  }

  return intSalary.toLocaleString(CURRENT_LOCALE, {
    minimumFractionDigits: hasDecimal ? 2 : 0,
  });
}

export function formatSalaryRange(
  salaryMinimum: number | string | null | undefined,
  salaryMaximum: number | string | null | undefined,
  country: string,
  currency: string | null | undefined,
) {
  const salaryMinimumAsNumber = Number(salaryMinimum);
  const salaryMaximumAsNumber = Number(salaryMaximum);

  if (
    salaryMinimumAsNumber &&
    salaryMaximumAsNumber &&
    salaryMinimumAsNumber !== salaryMaximumAsNumber
  ) {
    return `${formatSalary(salaryMinimumAsNumber, country, currency)} - ${
      currency === 'USD' ? '$' : ''
    }${formatSalary(salaryMaximumAsNumber, country)}`;
  }

  if (
    salaryMinimumAsNumber &&
    salaryMaximumAsNumber &&
    salaryMinimumAsNumber === salaryMaximumAsNumber
  ) {
    return formatSalary(salaryMinimumAsNumber, country, currency);
  }

  if (salaryMinimumAsNumber) {
    return sprintf(getText('At least %(salaryMinimum)s'), {
      salaryMinimum: formatSalary(salaryMinimumAsNumber, country, currency),
    });
  }

  if (salaryMaximumAsNumber) {
    return sprintf(getText('Up to %(salaryMaximum)s'), {
      salaryMaximum: formatSalary(salaryMaximumAsNumber, country, currency),
    });
  }

  return '';
}

export function formatSalaryRangeWithPeriodHumanName(
  salaryMinimum: number | string | null | undefined,
  salaryMaximum: number | string | null | undefined,
  country: string,
  currency: string | null | undefined,
  salaryPeriod: string,
) {
  const salaryText = formatSalaryRange(
    salaryMinimum,
    salaryMaximum,
    country,
    currency,
  );

  const periodText = salaryPeriod
    ? ` / ${getSalaryPeriodHumanName(salaryPeriod)}`
    : '';

  return salaryText + periodText;
}
