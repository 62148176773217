import { Fragment, ReactNode } from 'react';

import { Box } from 'components/Box/Box';
import { BodyMicro } from 'components/Text/BodyMicro';
import { ListingTypeLabel } from 'modules/listing/components/ListingTypeLabel/ListingTypeLabel';
import { SearchHitInfoBoxListingLabels } from 'modules/search/components/Hit/Info/ListingLabels/SearchHitInfoBoxListingLabels';
import {
  SearchHitInfoHeader,
  SearchHitInfoHeaderHeading,
  SearchHitInfoHeaderOrgName,
  SearchHitInfoHolder,
  SearchHitInfoPipe,
  SearchHitInfoStats,
  SearchHitInfoStatsRow,
} from 'modules/search/components/Hit/Info/SearchHitInfo.styled';

type Props = {
  hiring?: boolean;
  location?: string;
  listingStats: ReactNode[];
  orgStat?: ReactNode;
  name: string;
  orgType?: string | null;
  showLabel?: boolean;
  variant: 'search' | 'sidebar';
  pillItems: string[];
};

export function SearchOrgHitInfo({
  hiring,
  location,
  listingStats,
  orgStat,
  name,
  orgType,
  showLabel,
  variant,
  pillItems,
}: Props) {
  const type = 'ORG';
  return (
    <SearchHitInfoHolder $variant={variant}>
      {showLabel && (
        <div style={{ marginBottom: 14 }}>
          <ListingTypeLabel listingType={type} />
        </div>
      )}

      <SearchHitInfoHeader $variant={variant}>
        <SearchHitInfoHeaderHeading>
          <div>
            <span data-qa-id="search-result-link">{name.slice(0, 120)}</span>
            {name.length > 120 && <>&hellip;</>}
          </div>
        </SearchHitInfoHeaderHeading>

        {orgType === 'CORPORATION' && (
          <SearchHitInfoHeaderOrgName $variant={variant}>
            <Box display="flex" alignItems="center" flexWrap="wrap">
              {name}
            </Box>
          </SearchHitInfoHeaderOrgName>
        )}
      </SearchHitInfoHeader>

      <SearchHitInfoBoxListingLabels
        listingType={type}
        location={location}
        variant={variant}
        pillItems={pillItems}
      />

      <SearchHitInfoStatsRow>
        {listingStats.length > 0 && (
          <SearchHitInfoStats>
            {listingStats.map((info, index) => (
              // eslint-disable-next-line react/no-array-index-key
              <Fragment key={index}>
                {((index > 0 && !hiring) || (index > 1 && hiring)) && (
                  <SearchHitInfoPipe />
                )}
                <BodyMicro lineHeight={1}>{info}</BodyMicro>
              </Fragment>
            ))}
          </SearchHitInfoStats>
        )}
        {orgStat}
      </SearchHitInfoStatsRow>
    </SearchHitInfoHolder>
  );
}
