import { fromUnixTime } from 'date-fns';
import { ReactNode, useMemo } from 'react';
import { useLocation } from 'react-router-dom';
import { sprintf } from 'sprintf-js';

import { ApiISODateTimeString } from 'api/types/ApiTypedDate';
import { IdealistLogoOnDemand } from 'components/Logo/IdealistLogoOnDemand';
import { SearchAlgoliaVolopHit } from 'modules/search/algolia/types/SearchAlgoliaVolopHit';
import { SearchHitImage } from 'modules/search/components/Hit/Image/SearchHitImage';
import { SearchHitOwnerOrgBanner } from 'modules/search/components/Hit/OrgOwnerBanner/SearchHitOwnerOrgBanner';
import { SearchHitSaveButtonContainer } from 'modules/search/components/Hit/SaveButton/SearchHitSaveButtonContainer';
import {
  SearchHitCard,
  SearchHitHolder,
  SearchHitHoverEffect,
  SearchHitImageHolder,
  SearchHitLink,
  SearchHitSaveButtonHolder,
} from 'modules/search/components/Hit/SearchHit.styled';
import { SearchContextHeaderLocationState } from 'modules/search/containers/SearchContextHeader/SearchContextHeaderLocationState';
import { SearchFacet } from 'modules/search/types/SearchFacet';
import { getCityStateString } from 'utils/address/getCityStateString';
import { distanceInWordsToNow } from 'utils/date';
import { getCountryName, getUsStateName } from 'utils/internationalization';
import { useUserEnvironment } from 'zustand-stores/userEnvironmentStore';

import { SearchVolopHitInfo } from './SearchVolpHitInfo';

type Props = {
  hit: SearchAlgoliaVolopHit;
  trackClick?: () => void;
  showLabel?: boolean;
  hideCta?: boolean;
  hideOwnerBanner?: boolean;
  highlighted?: boolean;
  application?: {
    status: 'ACTIVE' | 'INACTIVE';
    created: ApiISODateTimeString;
  };
  variant: 'search' | 'sidebar';
  searchContextFacets?: SearchFacet[];
};

export function SearchVolopHit({
  hit,
  trackClick,
  showLabel,
  hideCta,
  hideOwnerBanner,
  highlighted,
  application,
  variant,
  searchContextFacets: searchContextFacetsProp,
}: Props) {
  const locationStateSearchContext =
    useLocation<SearchContextHeaderLocationState>().state?.showSearchContext;

  const searchContextFacets =
    searchContextFacetsProp || locationStateSearchContext?.searchFacets;

  const { user } = useUserEnvironment();

  const {
    name,
    published,
    city,
    state,
    country,
    startDate,
    startTime,
    endDate,
    endTime,
    endsLocal,
    startsLocal,
    timezone,
    orgID,
    orgName,
    orgType,
    source,
    locationType,
    remoteZone,
    logo,
    image,
    isPostedAnonymously,
    actionType,
  } = hit;

  const location = useMemo(() => {
    if (remoteZone !== undefined && remoteZone === 'WORLD') {
      return getText('Anywhere');
    }

    if (remoteZone !== undefined && remoteZone === 'COUNTRY') {
      return getCountryName(country);
    }

    if (remoteZone !== undefined && remoteZone === 'STATE') {
      return getUsStateName(state);
    }

    return getCityStateString({
      city,
      stateCode: state,
      country,
    });
  }, [city, country, remoteZone, state]);

  const listingStats = useMemo(() => {
    const stats: ReactNode[] = [];

    if (published) {
      stats.push(
        sprintf(getText('Posted %(postedAt)s'), {
          postedAt: distanceInWordsToNow(fromUnixTime(published)),
        }),
      );
    }

    stats.push(<IdealistLogoOnDemand withText height={20} />);

    return stats;
  }, [published]);

  const showSaveButton = !hideCta;

  const ownerOrg = user ? user.orgs.find((o) => o.id === orgID) : null;
  const startDateTime = startDate && startTime ? startsLocal : null;
  const endDateTime = endDate && endTime ? endsLocal : null;

  return (
    <SearchHitHolder data-qa-id="search-result" data-hit-id={hit.objectID}>
      {!hideOwnerBanner && ownerOrg && (
        <SearchHitOwnerOrgBanner org={ownerOrg} hit={hit} />
      )}

      <SearchHitCard>
        <SearchHitLink
          $variant={variant}
          $roundedTop={hideOwnerBanner || !ownerOrg}
          $highlighted={Boolean(highlighted)}
          data={{
            type: 'link-with-state',
            to: hit.url[CURRENT_LOCALE],
            state: searchContextFacets
              ? ({
                  showSearchContext: { searchFacets: searchContextFacets },
                } satisfies SearchContextHeaderLocationState)
              : {},
          }}
          onClick={() => trackClick?.()}
        >
          <SearchHitHoverEffect />

          <SearchHitImageHolder $hiddenOnMobile={!logo} $variant={variant}>
            <SearchHitImage
              isImported={false}
              imageUrl={undefined}
              image={image}
              source={source}
              name={name}
              logo={logo || undefined}
            />
          </SearchHitImageHolder>

          <SearchVolopHitInfo
            actionType={actionType}
            application={application}
            endDateTime={endDateTime}
            isPostedAnonymously={isPostedAnonymously}
            listingStats={listingStats}
            location={location}
            locationType={locationType}
            name={name}
            orgName={orgName}
            orgType={orgType}
            showLabel={showLabel}
            startDateTime={startDateTime}
            timezone={timezone}
            variant={variant}
          />
        </SearchHitLink>

        {showSaveButton && (
          <SearchHitSaveButtonHolder $variant={variant}>
            <SearchHitSaveButtonContainer hit={hit} />
          </SearchHitSaveButtonHolder>
        )}
      </SearchHitCard>
    </SearchHitHolder>
  );
}
