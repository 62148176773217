import { sprintf } from 'sprintf-js';

import { ApiISODateTimeString } from 'api/types/ApiTypedDate';
import { Box } from 'components/Box/Box';
import { BodyMicro } from 'components/Text/BodyMicro';
import { ListingStatusLabel } from 'modules/listing/components/ListingStatusLabel/ListingStatusLabel';
import { shorterDate } from 'utils/date';

type Props = {
  status: 'ACTIVE' | 'INACTIVE';
  created: ApiISODateTimeString;
};

export function SearchHitApplicationStatus({ status, created }: Props) {
  return (
    <>
      <ListingStatusLabel listingStatus={status} asTag forceSolid />

      <Box display="inline-block" ml={12}>
        <BodyMicro>
          {sprintf(getText('Applied %(date)s'), {
            date: shorterDate(created),
          })}
        </BodyMicro>
      </Box>
    </>
  );
}
