import { ApiListingLocationType } from 'api/types/ApiListingLocationType';
import { ApiISODateTimeString } from 'api/types/ApiTypedDate';
import { Box } from 'components/Box/Box';
import { Tooltip } from 'components/Tooltip/Tooltip';
import { ApiJobType } from 'modules/listing/api/job/types/ApiJobType';
import { ListingTypeLabel } from 'modules/listing/components/ListingTypeLabel/ListingTypeLabel';
import { SearchHitInfoBoxListingLabels } from 'modules/search/components/Hit/Info/ListingLabels/SearchHitInfoBoxListingLabels';
import {
  SearchHitInfoHeader,
  SearchHitInfoHeaderHeading,
  SearchHitInfoHeaderOrgName,
  SearchHitInfoHolder,
} from 'modules/search/components/Hit/Info/SearchHitInfo.styled';

import { SearchJobHitFooter } from './Footer/SearchJobHitFooter';

type Props = {
  application:
    | {
        status: 'ACTIVE' | 'INACTIVE';
        created: ApiISODateTimeString;
      }
    | undefined;
  jobType: ApiJobType[];
  isPostedAnonymously: boolean;
  location: string;
  locationType: ApiListingLocationType;
  name: string;
  orgName: string | undefined;
  orgType: string | undefined;
  salaryFormatted?: string;
  showLabel?: boolean;
  variant: 'search' | 'sidebar';
  published: number | null;
  earlyApplicant: boolean;
};

export function SearchJobHitInfo({
  application,
  jobType,
  isPostedAnonymously,
  location,
  locationType,
  name,
  orgName,
  orgType,
  salaryFormatted,
  showLabel,
  variant,
  published,
  earlyApplicant,
}: Props) {
  const type = 'JOB';

  return (
    <SearchHitInfoHolder $variant={variant}>
      {showLabel && (
        <div style={{ marginBottom: 14 }}>
          <ListingTypeLabel listingType={type} />
        </div>
      )}

      <SearchHitInfoHeader $variant={variant}>
        <SearchHitInfoHeaderHeading>
          <div>
            <span data-qa-id="search-result-link">{name.slice(0, 120)}</span>
            {name.length > 120 && <>&hellip;</>}
          </div>

          {isPostedAnonymously && (
            <Box display="inline-block" ml="4px" verticalAlign="middle">
              <Tooltip
                content={
                  <Box padding="6px">
                    {getText(
                      `The hiring organization has chosen to publish this listing anonymously to maintain confidentiality during the hiring process. They have provided Idealist with all of their organization’s details and currently meet our posting guidelines.`,
                    )}
                  </Box>
                }
                tooltipWidth={250}
                placement="bottom-right"
                size="small"
                variant="brand-blue"
              />
            </Box>
          )}
        </SearchHitInfoHeaderHeading>

        {(orgType === 'CORPORATION' || orgName) && (
          <SearchHitInfoHeaderOrgName $variant={variant}>
            <Box display="flex" alignItems="center" flexWrap="wrap">
              {orgName}
            </Box>
          </SearchHitInfoHeaderOrgName>
        )}
      </SearchHitInfoHeader>

      <SearchHitInfoBoxListingLabels
        listingType={type}
        location={location}
        jobType={jobType}
        locationType={locationType}
        salaryFormatted={salaryFormatted}
        variant={variant}
        noBorderAndMargin
      />

      <SearchJobHitFooter
        application={application}
        published={published}
        earlyApplicant={earlyApplicant}
        variant={variant}
      />
    </SearchHitInfoHolder>
  );
}
