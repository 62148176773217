import { Fragment, ReactNode } from 'react';

import { ApiListingLocationType } from 'api/types/ApiListingLocationType';
import { ApiISODateTimeString } from 'api/types/ApiTypedDate';
import { Box } from 'components/Box/Box';
import { LegacyTooltip } from 'components/LegacyTooltip/LegacyTooltip';
import { BodyMicro } from 'components/Text/BodyMicro';
import { ListingTypeLabel } from 'modules/listing/components/ListingTypeLabel/ListingTypeLabel';
import { SearchHitInfoBoxListingLabels } from 'modules/search/components/Hit/Info/ListingLabels/SearchHitInfoBoxListingLabels';
import {
  SearchHitInfoHeader,
  SearchHitInfoHeaderHeading,
  SearchHitInfoHeaderOrgName,
  SearchHitInfoHolder,
  SearchHitInfoPipe,
  SearchHitInfoStats,
  SearchHitInfoStatsRow,
} from 'modules/search/components/Hit/Info/SearchHitInfo.styled';
import { SearchHitApplicationStatus } from 'modules/search/components/Hit/SearchHitApplicationStatus';

type Props = {
  application?: {
    status: 'ACTIVE' | 'INACTIVE';
    created: ApiISODateTimeString;
  };
  isPostedAnonymously?: boolean;
  location?: string;
  locationType?: ApiListingLocationType;
  listingStats: ReactNode[];
  name: string;
  orgName?: string | null;
  orgType?: string | null;
  showLabel?: boolean;
  variant: 'search' | 'sidebar';
};

export function SearchInternshipHitInfo({
  application,
  isPostedAnonymously,
  location,
  locationType,
  listingStats,
  name,
  orgName,
  orgType,
  showLabel,
  variant,
}: Props) {
  const type = 'INTERNSHIP';

  return (
    <SearchHitInfoHolder $variant={variant}>
      {showLabel && (
        <div style={{ marginBottom: 14 }}>
          <ListingTypeLabel listingType={type} />
        </div>
      )}

      <SearchHitInfoHeader $variant={variant}>
        <SearchHitInfoHeaderHeading>
          <div>
            <span data-qa-id="search-result-link">{name.slice(0, 120)}</span>
            {name.length > 120 && <>&hellip;</>}
          </div>

          {isPostedAnonymously && (
            <Box display="inline-block" ml="4px" verticalAlign="middle">
              <LegacyTooltip width={360}>
                {getText(
                  `The hiring organization has chosen to publish this listing anonymously to maintain confidentiality during the hiring process. They have provided Idealist with all of their organization’s details and currently meet our posting guidelines.`,
                )}
              </LegacyTooltip>
            </Box>
          )}
        </SearchHitInfoHeaderHeading>

        {(orgType === 'CORPORATION' || orgName) && (
          <SearchHitInfoHeaderOrgName $variant={variant}>
            <Box display="flex" alignItems="center" flexWrap="wrap">
              {orgName}
            </Box>
          </SearchHitInfoHeaderOrgName>
        )}
      </SearchHitInfoHeader>

      <SearchHitInfoBoxListingLabels
        listingType={type}
        location={location}
        locationType={locationType}
        variant={variant}
      />

      <SearchHitInfoStatsRow>
        {(listingStats.length > 0 || application) && (
          <SearchHitInfoStats>
            {listingStats.map((info, index) => (
              // eslint-disable-next-line react/no-array-index-key
              <Fragment key={index}>
                {index > 0 && <SearchHitInfoPipe />}
                <BodyMicro lineHeight={1}>{info}</BodyMicro>
              </Fragment>
            ))}

            {application && (
              <SearchHitApplicationStatus
                status={application.status}
                created={application.created}
              />
            )}
          </SearchHitInfoStats>
        )}
      </SearchHitInfoStatsRow>
    </SearchHitInfoHolder>
  );
}
