import { isSameDay, parseISO, startOfDay } from 'date-fns';
import { useMemo } from 'react';
import styled from 'styled-components';

import { ApiListingLocationType } from 'api/types/ApiListingLocationType';
import { ApiISODateTimeString } from 'api/types/ApiTypedDate';
import { TagCombined } from 'components/Tag/TagCombined';
import { ApiJobType } from 'modules/listing/api/job/types/ApiJobType';
import { ListingLocationLabel } from 'modules/listing/components/ListingLocationLabel/ListingLocationLabel';
import { colors } from 'theme/theme';
import { shorterDate, shorterTime } from 'utils/date';

import {
  SearchHitInfoBoxListingLabelsHolder,
  SearchHitInfoBoxListingLabelsPill,
  SearchHitInfoBoxListingLabelsPillContainer,
} from './SearchHitInfoBoxListingLabels.styled';

type Props = {
  listingType: 'ORG' | 'JOB' | 'INTERNSHIP' | 'VOLOP' | 'EVENT' | 'IMPORTED';
  locationType?: ApiListingLocationType;
  location?: string;
  jobType?: ApiJobType[];
  salaryFormatted?: string;
  timezone?: string | null;
  startDateTime?: ApiISODateTimeString | null;
  endDateTime?: ApiISODateTimeString | null;
  variant: 'search' | 'sidebar';
  pillItems?: string[];
  noBorderAndMargin?: boolean;
};

const BottomTagContainer = styled.div`
  align-items: flex-start;
  display: flex;
  flex-flow: row wrap;
  gap: 8px;
`;

const formatDate = (
  startDateTime?: ApiISODateTimeString | null,
  endDateTime?: ApiISODateTimeString | null,
  timezone?: string | null,
) => {
  // 1/12/2024 - 4/02/2025
  if (
    startDateTime &&
    endDateTime &&
    !isSameDay(endDateTime, startDateTime) &&
    startOfDay(startDateTime).getTime() === parseISO(startDateTime).getTime() &&
    startOfDay(endDateTime).getTime() === parseISO(endDateTime).getTime()
  ) {
    return `${shorterDate(startDateTime)} - ${shorterDate(endDateTime)}`;
  }

  // 1/12/2024 10:00 AM - 5:00 PM EDT
  if (startDateTime && endDateTime && isSameDay(endDateTime, startDateTime)) {
    return `${shorterDate(startDateTime)} ${shorterTime(startDateTime, timezone, false)} - ${shorterTime(endDateTime, timezone, true)}`;
  }

  // 1/12/2024 10:00 AM - 4/02/2025 5:00 PM EDT
  if (startDateTime && endDateTime) {
    return `${shorterDate(startDateTime)} ${shorterTime(startDateTime, timezone, false)} - ${shorterDate(endDateTime)} ${shorterTime(endDateTime, timezone, true)}`;
  }
};

export function SearchHitInfoBoxListingLabels({
  listingType,
  location,
  jobType,
  locationType,
  salaryFormatted,
  timezone,
  startDateTime,
  endDateTime,
  variant,
  pillItems,
  noBorderAndMargin,
}: Props) {
  const jobTypeLabel = useMemo(() => {
    // Note: order is important here.
    if (jobType?.includes('CONTRACT')) return getText('Contract/Freelance');
    if (jobType?.includes('FULL_TIME')) return getText('Full Time');
    if (jobType?.includes('PART_TIME')) return getText('Part Time');
  }, [jobType]);

  return (
    <SearchHitInfoBoxListingLabelsHolder
      $variant={variant}
      $noBorderAndMargin={noBorderAndMargin}
    >
      {location && locationType && (
        <ListingLocationLabel
          location={location}
          type={locationType}
          breakLocation={location.length > 25}
        />
      )}

      <BottomTagContainer>
        {listingType === 'JOB' && (
          <TagCombined
            $thickLeftBorder
            $color={colors.mediumContentGrey}
            $bg={colors.white}
            $borderColor={colors.elementGrey}
          >
            {jobTypeLabel}
          </TagCombined>
        )}

        {salaryFormatted && (
          <TagCombined
            $thickLeftBorder
            $color={colors.mediumContentGrey}
            $bg={colors.white}
            $borderColor={colors.elementGrey}
          >
            {salaryFormatted}
          </TagCombined>
        )}

        {(startDateTime || endDateTime) && (
          <TagCombined
            $color={colors.white}
            $bg={colors.elementGrey}
            iconName="calendar"
            annex={formatDate(startDateTime, endDateTime, timezone)}
          />
        )}
        {pillItems && (
          <SearchHitInfoBoxListingLabelsPillContainer>
            {pillItems.map((pillItem) => (
              <SearchHitInfoBoxListingLabelsPill key={pillItem}>
                {pillItem}
              </SearchHitInfoBoxListingLabelsPill>
            ))}
          </SearchHitInfoBoxListingLabelsPillContainer>
        )}
      </BottomTagContainer>
    </SearchHitInfoBoxListingLabelsHolder>
  );
}
