import { useEffect, useMemo } from 'react';

import { useAbExperimentBucket } from 'hooks/abExperiment/useAbExperimentBucket';
import { useViewportBreakpoint } from 'hooks/viewport/useViewportBreakpoint';
import { trackEvent } from 'utils/analytics/track/trackEvent';

type Args = {
  earlyApplicant: boolean;
  listingHighlighted: boolean;
  listingIsHovered: boolean;
};

export function useSearchJobHitEarlyApplicantLabel({
  earlyApplicant,
  listingHighlighted,
  listingIsHovered,
}: Args): { earlyApplicantVisibility: 'always' | 'hover' | false } {
  const [earlyApplicantBucketValue] = useAbExperimentBucket(
    'EARLY_APPLICANT_LABEL_2024_11',
  );

  const earlyApplicantEnabled = earlyApplicantBucketValue === 'ENABLED';

  const viewportBreakpoint = useViewportBreakpoint();
  const isMobileBreakpoint =
    viewportBreakpoint === 'xs' || viewportBreakpoint === 'sm';

  const earlyApplicantVisibility = useMemo(() => {
    if (!earlyApplicantEnabled || !earlyApplicant) return false;

    if (listingHighlighted) return 'always';

    return 'hover';
  }, [earlyApplicant, earlyApplicantEnabled, listingHighlighted]);

  const earlyApplicantShowing = useMemo(() => {
    if (!earlyApplicantVisibility) return false;

    return (
      earlyApplicantVisibility === 'always' ||
      listingIsHovered ||
      isMobileBreakpoint
    );
  }, [earlyApplicantVisibility, isMobileBreakpoint, listingIsHovered]);

  useEffect(() => {
    if (earlyApplicantShowing) trackEvent('Early applicant label shown');
  }, [earlyApplicantShowing]);

  return { earlyApplicantVisibility };
}
