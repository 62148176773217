import styled, { css } from 'styled-components';

import { colors, fontWeights, h4Text, h5Text } from 'theme/theme';

export const SearchHitInfoHolder = styled.div<{
  $variant: 'search' | 'sidebar';
}>`
  display: flex;
  flex-direction: column;
  flex: 1;
  justify-content: space-between;

  /* Leave space for the save search button */
  word-break: break-word;

  ${(props) => {
    switch (props.$variant) {
      case 'search':
        return css`
          margin-right: 64px;
        `;
      case 'sidebar':
        return css`
          margin-right: 48px;
          max-width: 328px;
        `;
    }
  }};
`;

export const SearchHitInfoHeader = styled.div<{
  $variant: 'search' | 'sidebar';
}>`
  display: flex;
  flex-direction: column;

  ${(props) => {
    switch (props.$variant) {
      case 'search':
        return css`
          gap: 7px;
        `;
      case 'sidebar':
        return css`
          gap: 10px;
        `;
    }
  }};
`;

export const SearchHitInfoHeaderHeading = styled.h3`
  ${h4Text};
  line-height: 1.25;
  margin: 0;

  &:hover {
    color: ${colors.hoverBlue};
  }
`;

export const SearchHitInfoHeaderOrgName = styled.h4<{
  $variant: 'search' | 'sidebar';
}>`
  ${h5Text};
  margin: 0;
  line-height: 1.375;
  color: ${colors.mediumContentGrey};

  ${(props) => {
    switch (props.$variant) {
      case 'search':
        return css`
          font-weight: ${fontWeights.semiBold};
        `;
      case 'sidebar':
        return css`
          font-weight: ${fontWeights.normal};
        `;
    }
  }};
`;

export const SearchHitInfoStatsRow = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

export const SearchHitInfoStats = styled.div`
  align-items: center;
  color: ${colors.lightContentGrey};
  display: flex;
`;

export const SearchHitInfoPipe = styled.span`
  background: currentcolor;
  display: inline-block;
  height: 16px;
  margin: 4px 8px;
  opacity: 0.33;
  width: 1px;
`;

export const SearchHitInfoFundingMeterHolder = styled.div`
  margin-top: 8px;
`;
