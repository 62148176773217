import { fromUnixTime } from 'date-fns';
import { sprintf } from 'sprintf-js';

import { ApiISODateTimeString } from 'api/types/ApiTypedDate';
import {
  SearchHitInfoStats,
  SearchHitInfoStatsRow,
} from 'modules/search/components/Hit/Info/SearchHitInfo.styled';
import { SearchHitApplicationStatus } from 'modules/search/components/Hit/SearchHitApplicationStatus';
import { distanceInWordsToNow } from 'utils/date';

import {
  SearchJobHitFooterContent,
  SearchJobHitFooterEarlyApplicant,
  SearchJobHitFooterHolder,
  SearchJobHitFooterPublishedTimeago,
} from './SearchJobHitFooter.styled';

type Props = {
  application:
    | {
        status: 'ACTIVE' | 'INACTIVE';
        created: ApiISODateTimeString;
      }
    | undefined;
  published: number | null;
  earlyApplicant: boolean;
  variant: 'search' | 'sidebar';
};

export function SearchJobHitFooter({
  application,
  published,
  earlyApplicant,
  variant,
}: Props) {
  if (application)
    return (
      <SearchHitInfoStatsRow>
        <SearchHitApplicationStatus
          status={application.status}
          created={application.created}
        />
        <SearchHitInfoStats />
      </SearchHitInfoStatsRow>
    );

  if (!published && !earlyApplicant) return;

  return (
    <SearchJobHitFooterHolder>
      <SearchJobHitFooterContent>
        {published && (
          <SearchJobHitFooterPublishedTimeago>
            {sprintf(getText('Posted %(postedAt)s'), {
              postedAt: distanceInWordsToNow(fromUnixTime(published)),
            })}
          </SearchJobHitFooterPublishedTimeago>
        )}

        {earlyApplicant && (
          <SearchJobHitFooterEarlyApplicant
            $variant={variant}
            data-qa-id="search-job-hit-early-applicant-label"
          >
            🌱 {getText('Be the first to apply')}
          </SearchJobHitFooterEarlyApplicant>
        )}
      </SearchJobHitFooterContent>
    </SearchJobHitFooterHolder>
  );
}
