import { randomInt } from 'utils/functional/number/randomInt';

type Args<TBucketId extends string> = {
  buckets: Array<{ id: TBucketId; probability: number }>;
  /**
   * Number between 0 an 99
   */
  overrideAssignmentNumber?: number;
};

export function getABExperimentAssignmentBucketId<TBucketId extends string>({
  buckets: allBuckets,
  overrideAssignmentNumber,
}: Args<TBucketId>): TBucketId {
  // Make sure we never assign to a bucket with probability 0
  const buckets = allBuckets.filter((bucket) => bucket.probability > 0);

  const bucketAssignmentNumber =
    typeof overrideAssignmentNumber === 'undefined'
      ? randomInt(99)
      : overrideAssignmentNumber;

  let accumulatedProbability = 0;
  for (let i = 0; i < buckets.length; i++) {
    const bucket = buckets[i];
    accumulatedProbability += bucket.probability * 100;

    if (bucketAssignmentNumber < accumulatedProbability) return bucket.id;
  }

  return buckets[0].id;
}
