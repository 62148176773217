import styled, { css } from 'styled-components';

import { cssBreakpoints } from 'theme/theme';

import {
  searchJobHitOneWhenEarlyApplicantVisibleVar,
  searchJobHitTransparentWhenBorderHiddenVar,
  searchJobHitZeroWhenPostedDateHiddenVar,
} from './searchJobHitCssVariables';

const displayOnlyEarlyApplicantCss = css`
  ${searchJobHitZeroWhenPostedDateHiddenVar}: 0;
  ${searchJobHitOneWhenEarlyApplicantVisibleVar}: 1;
  ${searchJobHitTransparentWhenBorderHiddenVar}: transparent;
`;

export const SearchJobHitHolder = styled.div<{
  $variant: 'search' | 'sidebar';
  $earlyApplicantVisibility: 'always' | 'hover' | false;
}>`
  display: flex;
  flex-direction: column;

  ${(props) => {
    if (!props.$earlyApplicantVisibility) return;

    return css`
      @media all and (max-width: ${cssBreakpoints.mdDown}) {
        ${searchJobHitOneWhenEarlyApplicantVisibleVar}: 1;
      }

      @media all and (min-width: ${cssBreakpoints.mdUp}) {
        ${props.$earlyApplicantVisibility === 'always'
          ? displayOnlyEarlyApplicantCss
          : css`
              &:hover {
                ${displayOnlyEarlyApplicantCss};
              }
            `}
      }
    `;
  }}
`;
