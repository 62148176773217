import { useMemo } from 'react';
import { useLocation } from 'react-router-dom';

import { ApiISODateTimeString } from 'api/types/ApiTypedDate';
import { useHover } from 'hooks/useHover';
import { SearchAlgoliaJobHit } from 'modules/search/algolia/types/SearchAlgoliaJobHit';
import { SearchHitImage } from 'modules/search/components/Hit/Image/SearchHitImage';
import { SearchHitOwnerOrgBanner } from 'modules/search/components/Hit/OrgOwnerBanner/SearchHitOwnerOrgBanner';
import { SearchHitSaveButtonContainer } from 'modules/search/components/Hit/SaveButton/SearchHitSaveButtonContainer';
import {
  SearchHitCard,
  SearchHitHoverEffect,
  SearchHitImageHolder,
  SearchHitLink,
  SearchHitSaveButtonHolder,
} from 'modules/search/components/Hit/SearchHit.styled';
import { SearchContextHeaderLocationState } from 'modules/search/containers/SearchContextHeader/SearchContextHeaderLocationState';
import { SearchFacet } from 'modules/search/types/SearchFacet';
import { getCityStateString } from 'utils/address/getCityStateString';
import { getCountryName, getUsStateName } from 'utils/internationalization';
import { formatSalaryRangeWithPeriodHumanName } from 'utils/listings';
import { useUserEnvironment } from 'zustand-stores/userEnvironmentStore';

import { SearchJobHitHolder } from './SearchJobHit.styled';
import { SearchJobHitInfo } from './SearchJobHitInfo';
import { useSearchJobHitEarlyApplicantLabel } from './useSearchJobHitEarlyApplicantLabel';

type Props = {
  hit: SearchAlgoliaJobHit;
  trackClick?: () => void;
  showLabel?: boolean;
  hideCta?: boolean;
  hideOwnerBanner?: boolean;
  highlighted?: boolean;
  application?: {
    status: 'ACTIVE' | 'INACTIVE';
    created: ApiISODateTimeString;
  };
  variant: 'search' | 'sidebar';
  searchContextFacets?: SearchFacet[];
};

export function SearchJobHit({
  hit,
  trackClick,
  showLabel,
  hideCta,
  hideOwnerBanner,
  highlighted,
  application,
  variant,
  searchContextFacets: searchContextFacetsProp,
}: Props) {
  const locationStateSearchContext =
    useLocation<SearchContextHeaderLocationState>().state?.showSearchContext;

  const searchContextFacets =
    searchContextFacetsProp || locationStateSearchContext?.searchFacets;

  const { user } = useUserEnvironment();

  const {
    objectID,
    name,
    logo,
    city,
    state,
    country,
    published,
    orgName,
    orgType,
    orgID,
    jobType,
    locationType,
    remoteZone,
    isPostedAnonymously,
    salaryMinimum,
    salaryMaximum,
    salaryPeriod,
    salaryCurrency,
    url,
    earlyApplicant,
  } = hit;

  const location = useMemo(() => {
    if (remoteZone !== undefined && remoteZone === 'WORLD') {
      return getText('Anywhere');
    }

    if (remoteZone !== undefined && remoteZone === 'COUNTRY') {
      return getCountryName(country);
    }

    if (remoteZone !== undefined && remoteZone === 'STATE') {
      return getUsStateName(state);
    }

    return getCityStateString({
      city,
      stateCode: state,
      country,
    });
  }, [city, country, remoteZone, state]);

  const showSaveButton = !hideCta;

  const ownerOrg = user ? user.orgs.find((o) => o.id === orgID) : null;

  const salaryFormatted = useMemo(
    () =>
      salaryPeriod && (salaryMinimum || salaryMaximum)
        ? formatSalaryRangeWithPeriodHumanName(
            salaryMinimum ? parseFloat(salaryMinimum) : null,
            salaryMaximum ? parseFloat(salaryMaximum) : null,
            country,
            salaryCurrency,
            salaryPeriod,
          )
        : '',
    [salaryMinimum, salaryMaximum, country, salaryCurrency, salaryPeriod],
  );

  const [ref, listingIsHovered] = useHover();
  const { earlyApplicantVisibility } = useSearchJobHitEarlyApplicantLabel({
    earlyApplicant,
    listingHighlighted: Boolean(highlighted),
    listingIsHovered,
  });

  return (
    <SearchJobHitHolder
      ref={ref}
      data-qa-id="search-result"
      data-hit-id={objectID}
      $variant={variant}
      $earlyApplicantVisibility={earlyApplicantVisibility}
    >
      {!hideOwnerBanner && ownerOrg && (
        <SearchHitOwnerOrgBanner org={ownerOrg} hit={hit} />
      )}

      <SearchHitCard>
        <SearchHitLink
          $variant={variant}
          $roundedTop={hideOwnerBanner || !ownerOrg}
          $highlighted={Boolean(highlighted)}
          data={{
            type: 'link-with-state',
            to: url[CURRENT_LOCALE],
            state: searchContextFacets
              ? ({
                  showSearchContext: { searchFacets: searchContextFacets },
                } satisfies SearchContextHeaderLocationState)
              : {},
          }}
          onClick={() => trackClick?.()}
        >
          <SearchHitHoverEffect />

          <SearchHitImageHolder $hiddenOnMobile={!logo} $variant={variant}>
            <SearchHitImage
              isImported={false}
              imageUrl={undefined}
              image={undefined}
              source={undefined}
              name={name}
              logo={logo || undefined}
            />
          </SearchHitImageHolder>

          <SearchJobHitInfo
            application={application}
            jobType={jobType}
            isPostedAnonymously={isPostedAnonymously}
            location={location}
            locationType={locationType}
            name={name}
            orgName={orgName}
            orgType={orgType}
            salaryFormatted={salaryFormatted}
            showLabel={showLabel}
            variant={variant}
            published={published}
            earlyApplicant={Boolean(earlyApplicantVisibility)}
          />
        </SearchHitLink>

        {showSaveButton && (
          <SearchHitSaveButtonHolder $variant={variant}>
            <SearchHitSaveButtonContainer hit={hit} />
          </SearchHitSaveButtonHolder>
        )}
      </SearchHitCard>
    </SearchJobHitHolder>
  );
}
