import styled, { css } from 'styled-components';

import {
  searchJobHitOneWhenEarlyApplicantVisibleVar,
  searchJobHitTransparentWhenBorderHiddenVar,
  searchJobHitZeroWhenPostedDateHiddenVar,
} from 'modules/search/components/JobHit/searchJobHitCssVariables';
import { bodyMicroText, colors, cssBreakpoints, transition } from 'theme/theme';

export const SearchJobHitFooterHolder = styled.div`
  padding-top: 10px;
  border-top: 1px solid
    var(${searchJobHitTransparentWhenBorderHiddenVar}, ${colors.calloutGrey});
  font-size: 12px;
  position: relative;
  transition: ${transition};
  transition-property: border-top-color;

  @media all and (min-width: ${cssBreakpoints.mdUp}) {
    font-size: 14px;
  }
`;

export const SearchJobHitFooterContent = styled.div`
  position: relative;
  display: flex;
  align-items: center;

  @media all and (max-width: ${cssBreakpoints.lgDown}) {
    justify-content: space-between;
  }
`;

export const SearchJobHitFooterPublishedTimeago = styled.span`
  color: ${colors.lightContentGrey};
  ${bodyMicroText};
  line-height: 1;
  transition: ${transition};
  transition-property: opacity;
  opacity: var(${searchJobHitZeroWhenPostedDateHiddenVar}, 1);
`;

export const SearchJobHitFooterEarlyApplicant = styled.div<{
  $variant: 'search' | 'sidebar';
}>`
  display: flex;
  align-items: center;
  color: ${colors.natureGreen};

  transition: ${transition};
  transition-property: opacity;
  opacity: var(${searchJobHitOneWhenEarlyApplicantVisibleVar}, 0);

  @media all and (min-width: ${cssBreakpoints.mdUp}) {
    position: absolute;
    top: 0;
    left: 0;

    background: #f4fff1;
    background: linear-gradient(
      90deg,
      rgba(244 255 241 / 0%) 0%,
      rgba(244 255 241 / 100%) 20%,
      rgba(244 255 241 / 100%) 80%,
      rgba(244 255 241 / 0%) 100%
    );
  }

  ${(props) => {
    switch (props.$variant) {
      case 'search': {
        const horizontalPadding = 64;

        return css`
          @media all and (min-width: ${cssBreakpoints.mdUp}) {
            padding: 8px ${horizontalPadding}px 12px;
            margin-left: -${horizontalPadding}px; /* Offset padding left */
            margin-top: -10px; /* Offset padding top */
          }
        `;
      }
      case 'sidebar': {
        const xlPaddingLeft = 64;
        const lgPaddingLeft = 24;

        return css`
          padding-top: 8px;
          padding-bottom: 8px;
          margin-top: -12px;

          @media all and (min-width: ${cssBreakpoints.lgUp}) {
            padding-left: ${xlPaddingLeft}px;
            padding-right: 64px;
            margin-left: -${xlPaddingLeft}px; /* Offset padding */
          }

          @media all and (min-width: ${cssBreakpoints.mdUp}) {
            padding-left: ${lgPaddingLeft}px;
            padding-right: 64px;
            margin-left: -${lgPaddingLeft}px; /* Offset padding */
          }
        `;
      }
    }
  }};
`;
