import {
  ApiABExperimentBucketForId,
  ApiABExperimentId,
} from 'api/abExperiment/types/ApiABExperiment';

import {
  assignAnalyticsAbExperiments,
  getAnalyticsAbExperiments,
} from './analyticsAbExperiments';

export function assignAnalyticsAbExperimentBucket<
  TId extends ApiABExperimentId,
>(experimentId: TId, bucket: ApiABExperimentBucketForId<TId>) {
  const experiments = getAnalyticsAbExperiments();

  const existingValue = experiments.find(
    (experiment) => experiment.id === experimentId,
  );

  if (existingValue && existingValue.bucket === bucket) return;

  assignAnalyticsAbExperiments([
    { id: experimentId, bucket, created: new Date().toISOString() },
  ]);
}
