import styled, { css } from 'styled-components';

import { colors } from 'theme/theme';

export const SearchHitInfoBoxListingLabelsHolder = styled.div<{
  $variant: 'search' | 'sidebar';
  $noBorderAndMargin?: boolean;
}>`
  border-bottom: ${(props) =>
    !props.$noBorderAndMargin && `1px solid ${colors.calloutGrey}`};
  margin-bottom: ${(props) => !props.$noBorderAndMargin && '10px'};

  display: flex;
  align-items: flex-start;
  flex-direction: column;
  gap: 10px;

  ${(props) => {
    switch (props.$variant) {
      case 'search':
        return css`
          padding: 15px 0 10px;
        `;
      case 'sidebar':
        return css`
          padding: 10px 0;
        `;
    }
  }};
`;

export const SearchHitInfoBoxListingLabelsPillContainer = styled.div`
  margin-bottom: 10px;
`;

export const SearchHitInfoBoxListingLabelsPill = styled.span`
  height: 20px;
  border: solid 1px;
  border: solid 1px ${colors.elementGrey};
  border-radius: 12px;
  padding: 4px 8px;
  color: ${colors.black};
  font-size: 12px;
  margin-right: 8px;
`;
