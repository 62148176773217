import styled, { css, keyframes } from 'styled-components';

import { Box } from 'components/Box/Box';

const aFill = keyframes`
  from {
    transform: scaleX(0.001);
  }

  to {
    transform: scaleX(1);
  }
`;

export const AmountContainer = styled.div<{
  $duration: number;
  $progress: number;
}>`
  ${({ $progress, $duration }) => css`
    width: ${$progress * 100}%;
    transition: width ${$duration}s ease;
  `}
`;

export const Amount = styled(Box)<{ $duration: number; $delay: number }>`
  transform: scaleX(0.001);
  transform-origin: left center;
  animation: ${aFill} ${(props) => props.$duration}s ${(props) => props.$delay}s
    forwards;
`;
