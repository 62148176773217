import { useEffect } from 'react';

import {
  ApiABExperimentBucketForId,
  ApiABExperimentId,
} from 'api/abExperiment/types/ApiABExperiment';
import { apiABExperiments } from 'api/abExperiment/types/apiABExperiments';
import { assignAnalyticsAbExperimentBucket } from 'utils/analytics/abExperiments/assignAnalyticsAbExperimentBucket';
import { setABExperimentBucket } from 'zustand-stores/userEnvironmentStore';

import { useInternalABExperimentBucketAssignedValue } from './useInternalABExperimentBucketAssignedValue';

const experimentsDispatched: {
  [bucketId in ApiABExperimentId]: ApiABExperimentBucketForId<bucketId>;
} = {};

/**
 * Returns a bucket for the given `experimentId` if the user environment is ready,
 * otherwise returns the default bucket
 */
export function useAbExperimentBucket<TExperimentId extends ApiABExperimentId>(
  experimentId: TExperimentId,
): [ApiABExperimentBucketForId<TExperimentId>, { isLoading: boolean }] {
  const value = useInternalABExperimentBucketAssignedValue(experimentId) || {
    type: 'default',
    bucketId: apiABExperiments[experimentId].defaultBucket,
  };

  const isAssignedOnFrontend = value.type === 'assigned-on-frontend';
  const { bucketId } = value;

  // Update analytics as soon as possible to make sure we have the latest data instead of `undefined`
  assignAnalyticsAbExperimentBucket(experimentId, bucketId);

  useEffect(() => {
    if (!isAssignedOnFrontend) return;
    if (experimentsDispatched[experimentId] === bucketId) return;

    experimentsDispatched[experimentId] = bucketId;
    setABExperimentBucket(experimentId, bucketId);
  }, [bucketId, experimentId, isAssignedOnFrontend]);

  return [bucketId, { isLoading: value.type === 'default' }];
}
