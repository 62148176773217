import { useMemo } from 'react';

import { ApiListingLocationType } from 'api/types/ApiListingLocationType';
import { TagCombined, TagSimple } from 'components/Tag/TagCombined';
import { colors } from 'theme/theme';
import { LOCATION_TYPE } from 'utils/constants/general/locationType';

import { ListingLocationLabelHolder } from './ListingLocationLabel.styled';

export function ListingLocationLabel({
  location,
  type,
  breakLocation,
}: {
  location: string;
  type: ApiListingLocationType;
  breakLocation?: boolean;
}) {
  const styles = useMemo(() => {
    switch (type) {
      case 'REMOTE':
        return {
          bg: colors.brightPlum,
          color: 'white',
        };

      case 'HYBRID':
        return {
          bg: colors.secondarySkyBlue,
          color: 'white',
        };

      case 'ONSITE':
      default:
        return {
          bg: colors.brandGreen,
          color: 'white',
        };
    }
  }, [type]);

  if (breakLocation) {
    return (
      <ListingLocationLabelHolder>
        <TagCombined
          $color={styles.color}
          $bg={styles.bg}
          iconName="location-filled"
        >
          {LOCATION_TYPE[type]}
        </TagCombined>
        <TagSimple
          $color={colors.mediumContentGrey}
          $borderColor={styles.bg}
          $bg={colors.white}
        >
          {location}
        </TagSimple>
      </ListingLocationLabelHolder>
    );
  }

  return (
    <TagCombined
      $color={styles.color}
      $bg={styles.bg}
      annex={location}
      iconName="location-filled"
    >
      {LOCATION_TYPE[type]}
    </TagCombined>
  );
}
